import {Container, Row, Col} from 'react-bootstrap'
import WongNumberRooster from '../art/WongNumberRooster'

export default function About() {
  document.title = "Master Wong's -  About Us"

    return (
        <>
        <Container fluid className="content section-title"
      style={{paddingTop:"7rem"}}
        
        >

         <h2>About</h2>

</Container>
<Container fluid className="py-5">
  


         <Row>
            
            <Col md={4} className="">
                {/* <img className="img-fluid" src="images/rooster.png" alt="Call the Wong Number"> */}
                    <WongNumberRooster color="var(--darkred)" />
                </Col>

          <Col md={8} className="d-flex flex-column justify-content-center align-items-stretch px-5">

            <div className="">
              <h3 className="fs-1">The history of <strong>Master Wong's</strong></h3>
              <p>
                Cashew Chicken is a staple in Southwest Missouri.  Deep fried chunks of chicken covered with a rich, thick sauce and sprinkled with green onions and cashew halves have been enjoyed in Springfield, Missouri and surrounding communities for a half decade.
              </p>
                <p>Master Wong's is one of the original restaurants serving Springfield-style Cashew Chicken, Started in 1974, the chain grew to as many as 11 restaurants, including franchises in Little Rock, Arkansas, Hammond, Indiana, and St. Joseph, Missouri.</p>
                <p>Master Wong's in Bolivar has only had three owners since its opening in 1982.  The current owner is Gary Young, who took over in 2010.  He was not a restauranteur, far from it.  Gary was a commodities trader in Kansas City.  He went to school at SBU in Bolivar and always had an affinity for Master Wong's food. </p>
                <p>Why did Gary Young buy the restaurant from Jo Helen Beauchamp?  <em>"I loved the food so much and tried as hard as I could to get the recipe so I could make my own Master Wong's Cashew Chicken.  No one would give me the secret recipe for their famous Cashew Chicken, so I bought the place!"</em>, said Gary.</p>
                <p>Gary Young has made many improvements to Master Wong's, including the addition of several menu items such as General Tso's Chicken, Thai Sweet Chili Chicken, Beef & Broccoli, and much more.</p> 
              
            

            </div>

          </Col>

        </Row>
        </Container>



        <Container fluid className="p-5 content">
<Row>
    <Col lg={4} className="d-flex justify-content-center">
        <img className="img-fluid" src="https://masterwongs.com/images/pamharris.jpg" alt="Pam Harris" />

        </Col>

  <Col className="d-flex flex-column justify-content-center align-items-stretch p-3">

      <h3>Meet Chef Pam <strong>Harris</strong></h3>
      <p>
        For nearly a quarter century, Pam has been a part of the Master Wong’s family.  After the passing of the torch to current owner Gary Young in 2010, she was promoted to the Store Manager position.  She was responsible for implementing the roll out of several tasty new menu items after Young bought Master Wong’s, including Wong Pow Chicken, Lo Mein dinners, desert items, and more.
      </p>
      <p className="font-italic">
        Being a fast food Manager is very challenging with long hours, but Pam loves what she does.  “I enjoy serving our food to the hundreds of customers in the Bolivar area, especially those that come in multiple times per week.  It’s also satisfying working for Gary, who makes us feel like we’re part of his family”.   Pam and her crew are very appreciated.
      </p>


  </Col>
</Row>
        </Container>
        </>
    )
}

import { Container, Row, Col } from "react-bootstrap";
import { BiMap } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";

export default function Contact() {
  document.title = "Master Wong's - Contact Us";

  return (
    <>
      <Container fluid className="section-title m-0 px-0 pb-0"
      style={{paddingTop:"7rem"}}
      >

          <h2 className="mb-5">
            <span>Contact</span> Us
          </h2>


        <iframe
        title="Map"
          style={{ border: 0 }}
          width="100%"
          height="350px"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.8995530288644!2d-93.40895744903058!3d37.60452297969174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c5bfee8a5244eb%3A0x17f3335483c88684!2s1003%20S%20Springfield%20Ave%2C%20Bolivar%2C%20MO%2065613!5e0!3m2!1sen!2sus!4v1589306527601!5m2!1sen!2sus"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </Container>

      <Container fluid className="p-5 content">
        <div className="info-wrap">
          <Row>
            <Col lg={6} md={6} className="info">
              <h4 style={{color:"var(--red)"}}>
                <BiMap /> Location:
              </h4>
              <p>
                1003 S Springfield Ave
                <br />
                Bolivar, MO 65613
              </p>
            </Col>

            <Col lg={6} md={6} className="info mt-sm-4 mt-lg-0">
              <h4 style={{color:"var(--red)"}}>
                <HiOutlineClock /> Open Hours:
              </h4>
              <p>
                <strong>Monday-Thursday:</strong>
                <br />
                11:00 AM - 9:00 PM
              </p>
              <p>
                <strong>Friday-Saturday:</strong>
                <br />
                11:00 AM - 10:00 PM
              </p>

              <p>
                <strong>Sunday:</strong>
                <br />
                12:00 PM - 8:00 PM
              </p>
            </Col>

            <Col lg={6} md={6} className="info mt-4 mt-lg-4">
              <h4 style={{color:"var(--red)"}}>
                <FiMail /> Email:
              </h4>
              <p>gary.young@masterwongs.com</p>
            </Col>

            <Col lg={6} md={6} className="info mt-4 mt-lg-4">
              <h4 style={{color:"var(--red)"}}>
                <BsTelephone /> Call:
              </h4>
              <p>(417) 326-6056</p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

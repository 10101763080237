import { Container, Navbar, Nav, Button, Row, Col } from "react-bootstrap"
// import { NavLink } from "react-router-dom"
// import MasterWongsLogoReversed from "../art/MasterWongsLogoReversed"
import {default as Logo} from '../art/MasterWongsLogoReversed.svg'
import {BsTelephoneFill, BsFillClockFill} from 'react-icons/bs'

export default function MenuMain() {
    return (
        <Container fluid className="menu m-0 p-0 fixed-top"
        style={{backgroundColor:"rgba(53,50,45,0.9"}}
        >

        <Navbar collapseOnSelect expand="md" 
        // bg="dark" 
        variant="dark" className="px-3">
            <Navbar.Brand href="/">
                {/* MASTER WONGS */}
                <img
                src={Logo}
                alt="Master Wongs"
                width="200"
        className="d-inline-block align-top"
                />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" className=""/>

                <Navbar.Collapse className="">

<Nav className="ms-auto d-flex justify-content-end me-3">
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
</Nav>
<Button href="https://clover.com/online-ordering/master-wongs-bolivar" className="bg-orange rounded-pill"
      rel="noopener noreferrer"
      target="_blank"
      
      >Order Now</Button>

<Container className= 'p-0 m-0'>
<Row className="text-end text-white pt-2 pb-0 d-flex justify-content-end">
  <Col lg={6}  className="d-flex justify-content-end align-items-center">
<p className="me-1">
<span style={{color:"var(--orange)"}} >
<BsTelephoneFill /> 
</span>
(417) 326-6056 
</p>
  </Col>
  <Col lg={6}  className="pe-3 d-flex justify-content-end">
<small>
<span style={{color:"var(--orange)"}} >
<BsFillClockFill /> </span>
<strong>Mon-Thurs:  
</strong> 11am-9:00pm  <br />  
<strong> Fri-Sat
</strong> 11am-10pm  <br /> 
<strong> Sun</strong> 12pm-8pm
</small>
  </Col>
</Row>
</Container>


                </Navbar.Collapse>
        </Navbar>
        </Container>
    )
}

import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import LogoClassic from "../art/LogoClassic";
import { AiFillStar } from "react-icons/ai";
import { useSpring, animated, easings } from "react-spring";
import { BsTelephoneFill } from "react-icons/bs";

export default function Home() {
  document.title = "Master Wong's";

  const titleOne = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -50 },
    pause: 2000,
    // loop: true,
    delay: 500,
    config: {
      duration: 2000,
      mass: 500,
      friction: 500,
      easing: easings.easeInOutQuad,
    },
  });
  const titleTwo = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    pause: 2000,
    // loop: true,
    delay: 2500,
    config: {
      duration: 2000,
      mass: 500,
      friction: 500,
      easing: easings.easeInOutQuad,
    },
  });

  return (
    <>
      <Container
        fluid
        className="p-5 
        d-flex flex-column align-items-center justify-content-center
        hero
        "
        style={{
          backgroundImage: "url(https://masterwongs.com/images/cashew.jpg)",
        }}
      >
        <div className="mx-md-5">
          <animated.h2 style={titleOne} className="mt-5 pt-5">
            <span>Master Wong's</span> Uses Fresh Ingredients
          </animated.h2>
          <animated.p style={titleTwo} className="text-center fs-5">
            Original Springfield-style Cashew Chicken, Eqg Rolls, Crab Rangoon
            and much more made from scratch with quality ingredients
          </animated.p>
        </div>
        <p className="h3 me-1">
          <span style={{ color: "var(--orange)" }}>
            <BsTelephoneFill />
          </span>
          (417) 326-6056
        </p>
        {/* <p className="fs-4">Click Order Delivery and search for Master Wong's</p> */}
        <Button href="https://clover.com/online-ordering/master-wongs-bolivar" className="bg-orange rounded-pill mt-4"
      rel="noopener noreferrer"
      target="_blank"
      
      >Order TakeOut & Delivery</Button>
  
{/* <div className="p-5 text-dark bg-light my-3">

      <p className="lead">To make a better experience for you, we have obtained DoorDash for our delivery system!</p>
            <p>This will give you quicker deliveries, special deals, plus more efficiency in our kitchen and dining room.</p>
            <p className="fw-bold">Simply click Order Now and search Master Wong's on DoorDash.com</p>
</div> */}
      </Container>

      <Container fluid className="bg-darkred pt-1">
        <Row>
          <Col
            className="position-relative d-flex justify-content-center align-items-center"
            md={6}
            style={{
              // backgroundImage:"url(https://masterwongs.com/assets/img/slide/slide-2.jpg)", backgroundSize:"cover",
              height: "350px",
            }}
          >
            <Image
              fluid
              src="https://masterwongs.com/images/takeout550.png"
              className="position-relative"
              style={{
                width: "550px",
                zIndex: "1",
                top: "-25px",
                left: "30px",
              }}
            />
          </Col>

          <Col
            className="bg-darkred text-white p-5 text-center d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: "200px" }}
          >
            <h2>
              <span>Wong's</span> Delivers
            </h2>
            <p className="lead">
              Fresh & tasty dinners and sides delivered right to your door.
            </p>

            {/* <div>
              <a
                href="https://doordash.com"
                target="_blank"
                className="btn btn-orange"
                rel="noopener noreferrer"
              >
                Order Delivery
              </a>
            </div> */}
          </Col>
        </Row>
      </Container>

      {/* --------SPECIALS------------- */}
      <Container fluid className="bg-orange">
        <Row className="p-0 m-0">
          <Col md={6} className="my-3 mx-0">
            <Card className="p-3 h-100">
              <Card.Img
                src="https://masterwongs.com/images/Daily-Lunch-Special-plate.jpg"
                alt="Lunch Special"
                className="img-fluid"
              />
              <Card.Body className="">
                <h3>Lunch Special</h3>
                <p className="fw-bold fs-1 lh-sm">
                  CASHEW OR SWEET & SOUR CHICKEN
                </p>
                <p>Choice of side:</p>
                <p>EGG ROLL, CRAB RANGOON, OR WON TONS</p>
                <p>Regular Fountain Drink</p>
                <p className="fst-italic">General, Orange, OR Thai + $.50,</p>
                <p className="fw-bold text-center">Daily until 4:00</p>
                <div className="text-center">
                  {/* <a
                    href="https://doordash.com"
                    target="_blank"
                    className="btn btn-red"
                    rel="noopener noreferrer"
                  >
                    Order Now
                  </a>{" "} */}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="my-3 mx-0">
            <Card className="p-3 h-100">
              <Card.Img
                src="https://masterwongs.com/images/Daily-Dinner-Special.jpg"
                alt="Dinner Special"
                className="img-fluid"
              />
              <Card.Body className="mt-5">
                <h3>Dinner Special</h3>
                <p className="fw-bold fs-1 lh-sm">
                  CASHEW OR SWEET & SOUR CHICKEN
                </p>
                <p>Choice of side:</p>
                <p>EGG ROLL, CRAB RANGOON, OR WON TONS</p>
                <p>Regular Fountain Drink</p>
                <p className="fst-italic">General, Orange, OR Thai + $.50</p>
                <p className="fw-bold text-center">Daily 4:00PM until close</p>
                <div className="text-center">
                  {/* <a
                    href="https://doordash.com"
                    target="_blank"
                    className="btn btn-red"
                    rel="noopener noreferrer"
                  >
                    Order Now
                  </a>{" "} */}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid className="px-4 bg-orange">
        <Card className="p-lg-3 p-sm-1 m-0">
          <Row className="m-0 p-0">
            <Col className="d-flex justify-content-center">
              <Image
                fluid
                src="https://masterwongs.com/images/specials-3.jpg"
                alt="Family Special"
                className=""
              />
            </Col>
            <Col md={6} className="p-3">
              <h3>Family Dinners</h3>

              <p className="fs-3">
                <strong>Wong's Family Pack 1</strong>
              </p>
              <p>
                20 OZ Cashew OR S&S Chicken (4 REG’S Worth), 1 QT Rice{" "}
                {/* <strong>$19.49</strong>{" "} */}
              </p>

              <p className="fs-3">
                <strong>Wong's Family Pack 2</strong>
              </p>

              <p>
                30 OZ Cashew OR S&S Chicken (6 REG’S Worth), 1½ QT Rice{" "}
                {/* <strong>$23.49</strong>{" "} */}
              </p>

              <p className="fs-3">
                <strong>Wong's Feast of Four</strong>
              </p>

              <p>
                20 OZ Chicken, 1 QT Rice and Choice of 4 sides{" "}
                {/* <strong>$23.49</strong>{" "} */}
              </p>
              <div className="text-center">
                {/* <a
                    href="https://doordash.com"
                    target="_blank"
                    className="btn btn-red"
                    rel="noopener noreferrer"
                  >
                    Order Now
                  </a>{" "} */}
              </div>
            </Col>
          </Row>
        </Card>
      </Container>

      {/* ----------ICON-------------- */}
      <Container fluid className="bg-orange">
        {/* <h2 className=""><span></span></h2> */}
        <Row>
          <Col className="d-flex align-items-center justify-content-center p-5">
            <h4 className="text-center fw-bold text-uppercase fs-1 text-white">
              An icon in the Bolivar community since 1982
            </h4>
          </Col>
          <Col md={6} className="p-5">
            <LogoClassic />
          </Col>
        </Row>
      </Container>

      {/* ------TESTIMONIALS---------- */}
      <Container fluid className="bg-dark text-white p-5">
        <Row>
          {testimonials.map((t, i) => {
            return <TestimonialBlock {...t} key={i} />;
          })}
        </Row>
      </Container>
    </>
  );
}

export function TestimonialBlock({ name, quote }) {
  return (
    <Col md={6} className="text-center p-4">
      <h4>{name}</h4>
      <div className="stars">
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
      <p>{quote}</p>
    </Col>
  );
}

const testimonials = [
  {
    name: "Jon",
    quote:
      "Always the best...i stop by everytime I drive through bolivar. Been coming here for 30 years",
  },
  {
    name: "Emilee",
    quote:
      " Best establishment in the town. No where better to get food in bolivar and there is a taco bell. SO AM saying alot.",
  },
  {
    name: "Michael",
    quote:
      "Always the best cashew chicken. My go to place when I'm in Bolivar.",
  },
  {
    name: "Tiffanie",
    quote:
      "I grew up eating Master Wongs through takeout and delivery. Its one of the best Chinese food experience Ive had.",
  },
  {
    name: "Mark",
    quote:
      "Drove and hour to eat at our favorite place and we're not disappointed. Best crab Rangoon and fried rice in the state! Will be back soon!",
  },
];

import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'
// import { useEffect } from 'react';
// import { createBrowserHistory } from "history";
import Home from './pages/Home';
import MenuMain from './components/MenuMain';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import About from './pages/About'
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';
import './App.css';


function App() {

//   function FindToastExplore(e) {
//   axios({
//     method:'post',
//     url: 'https://masterwongs/authentication/v1/authentication/login',
//     clientId: '123',
//     clientSecret: 'asdf',
//     userAccesType: 'TOAST_MACHINE_CLIENT'

//   })
// }

ReactGA.initialize("G-LEYBH7QH93");
ReactGA.send({ hittype:"pageview", page: window.location.pathname });

// const history = createBrowserHistory();
// history.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

// useEffect(() => {
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }, [history]);

  return (
    <Container className="p-0 m-0 frame" fluid>
<MenuMain />
<BrowserRouter>
<Routes>
  <Route path="/" element={<Home />} />
  <Route path="contact" element={<Contact />} />
  <Route path="about" element={<About />} />
  <Route path="*" element={<NotFound />} />
</Routes>
</BrowserRouter>

<Footer />

    </Container>
  );
}

export default App;

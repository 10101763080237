import { Container, Row, Col } from "react-bootstrap"
import {BsTwitter, BsFacebook} from 'react-icons/bs'
import {default as Logo} from '../art/Master-Wongs-Yellow-On-Red.svg'

import { BiMap } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";

export default function Footer() {
    const year = new Date().getFullYear()

    return (
        <Container className="text-white p-5 d-flex flex-column align-items-center" fluid
        style={{backgroundColor: "rgb(53, 50, 45)"}}
        >

<div style={{width:"200px"}}>
           <img src={Logo} alt="Master Wong's" />
</div>

<h3 className="mt-4">Call the Wong Number</h3>

<div className="social-links my-3">
        <a rel="noreferrer noopener" href="https://twitter.com/masterwongs" target="_blank" className="social m-1"><BsTwitter size={30} /></a>
        <a rel="noreferrer noopener" href="https://www.facebook.com/masterwongs/" target="_blank" className="social m-1">
        <BsFacebook size={30} />
          </a>
</div>


<Row className="mt-5">
            <Col lg={6} md={6} className="info">
              <h4 style={{color:"var(--orange)"}}>
                <BiMap /> Location:
              </h4>
              <p>
                1003 S Springfield Ave
                <br />
                Bolivar, MO 65613
              </p>
            </Col>

            <Col lg={6} md={6} className="info mt-sm-4 mt-lg-0">
              <h4 style={{color:"var(--orange)"}}>
                <HiOutlineClock /> Open Hours:
              </h4>
              <p>
                <strong>Monday-Thursday:</strong>
                <br />
                11:00 AM - 9:00 PM
              </p>
              <p>
                <strong>Friday-Saturday:</strong>
                <br />
                11:00 AM - 10:00 PM
              </p>

              <p>
                <strong>Sunday:</strong>
                <br />
                12:00 PM - 8:00 PM
              </p>
            </Col>

            <Col lg={6} md={6} className="info mt-4 mt-lg-4">
              <h4 style={{color:"var(--orange)"}}>
                <FiMail /> Email:
              </h4>
              <p>gary.young@masterwongs.com</p>
            </Col>

            <Col lg={6} md={6} className="info mt-4 mt-lg-4">
              <h4 style={{color:"var(--orange)"}}>
                <BsTelephone /> Call:
              </h4>
              <p>(417) 326-6056</p>
            </Col>
          </Row>


<p className="mt-5">
                   <small>©{year} Master Wong's</small>
</p>


        </Container>
    )
}
